export const main_elements_ids = [
  "welcome",
  "sale",
  "offers",
  "products",
  "workingstages",
  "consultation",
  "contacts",
  "partners",
  "form",
];

export const product_elements_ids = ["contacts", "partners", "product-info"];
