import Line1 from "../assets/ЗНИЖКА -30%.png";
import Line2 from "../assets/ЗНИЖКА -30% (1).png";
import Line1_1 from "../assets/СКИДКА -30%.png";
import Line2_1 from "../assets/СКИДКА -30% (1).png";

export const marquee_images = [
  {
    image_1: Line1,
    image_2: Line1_1,
  },
  {
    image_1: Line2,
    image_2: Line2_1,
  },
  {
    image_1: Line1,
    image_2: Line1_1,
  },
  {
    image_1: Line2,
    image_2: Line2_1,
  },
];
